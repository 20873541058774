<template>
  <section id="notFound" class="centralize">
    <div class="page-base-container">
      <img src="../assets/imagens/logotipo_green_10.png" alt="Escala10" />
      <h1>Sem Permissão!</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
#notFound {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imagens/page-base.png");
  background-position: center;
  background-size: contain;
  background-size: cover;
}
</style>
